.emoji-mart {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  box-shadow: theme('boxShadow.quickmenu');
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border-bottom: 0px solid theme('colors.gray.200');
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emoji-mart-category {
  display: flex;
  flex-direction: column;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 0;
  @apply px-2;
}

.emoji-mart-anchor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
  @apply px-2;
  @apply py-3;
  @apply text-gray-400;
}
.emoji-mart-anchor:focus {
  outline: 0;
}
.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus {
  @apply text-gray-700;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: theme('colors.gray.800') !important;
  @apply rounded-t-lg;
}

.emoji-mart-anchor-selected {
  color: theme('colors.gray.800') !important;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px 6px;
  display: flex;
  flex-direction: column;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin: 0 8px;
  @apply pt-2;
  position: relative;
}

.emoji-mart-search input {
  @apply font-medium;
  @apply text-sm;
  @apply pr-3;
  @apply pl-8;
  @apply py-2;
  @apply placeholder-gray-500;
  @apply bg-gray-150;

  display: block;
  width: 100%;
  border-radius: 8px;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 20px;
  left: 12px;
  z-index: 2;
  border: none;
  background: none;
  fill: theme('colors.gray.600');
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  @apply bg-gray-100;
}

.emoji-mart-category .emoji-mart-emoji:focus:before {
  @apply bg-gray-100;
}

.emoji-mart-category-label {
  z-index: 2;
  position: sticky;
  top: -1px;
  width: 100%;
  display: flex;
  @apply bg-white;
  @apply px-2;
  @apply py-3;
}

.emoji-mart-category-label span {
  display: flex;
  width: 100%;
  font-size: 11px;
  padding: 0;
  @apply tracking-wider;
  @apply font-black;
  @apply uppercase;
  @apply text-gray-500;
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI',
    'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'Android Emoji';
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}
.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}
.emoji-mart-no-results .emoji-mart-no-results-label {
  @apply mt-1;
}
.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 56px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 6px;
}

.emoji-mart-preview-data {
  left: 48px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 16px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  @apply truncate;
  @apply text-xs;
  @apply font-medium;
}

.emoji-mart-preview-shortname {
  @apply text-xs;
  @apply text-gray-500;
}

.emoji-mart-preview-shortnames {
  display: flex;
  align-items: center;
}

.emoji-mart-preview-emoticons {
  display: none;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label,
.emoji-mart-no-results-label {
  @apply text-gray-500;
  @apply text-sm;
  @apply font-medium;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 4px 2px;
  border-radius: 12px;
  @apply bg-gray-100;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}
.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}
.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}
.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}
.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}
.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: '';
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 36px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened
  .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}
.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}
.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}
.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}
.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}
.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  display: none;
}

/*
 * Dark mode styles
 */

.emoji-mart-dark {
  @apply bg-gray-900;
}

.emoji-mart-dark .emoji-mart-anchor-bar {
  background-color: theme('colors.white') !important;
}

.emoji-mart-dark .emoji-mart-anchor-selected {
  color: theme('colors.white') !important;
}

.emoji-mart-dark .emoji-mart-bar {
  @apply border-gray-800;
}

.emoji-mart-dark .emoji-mart-search input {
  @apply placeholder-gray-400;
  @apply text-gray-100;
  @apply bg-gray-750;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: theme('colors.gray.200');
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  @apply bg-gray-700;
}

.emoji-mart-dark .emoji-mart-category-label {
  @apply bg-gray-900;
  @apply text-gray-400;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  @apply bg-gray-800;
}

.emoji-mart-dark .emoji-mart-title-label,
.emoji-mart-dark .emoji-mart-no-results-label {
  @apply text-gray-400;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  @apply text-gray-200;
}

.emoji-mart .emoji-mart-preview-emoji > span > span {
  height: 24px !important;
  width: 24px !important;
}
