@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }
  .font-extralight {
    font-variation-settings: 'wght' 200;
  }
  .font-light {
    font-variation-settings: 'wght' 300;
  }
  .font-normal {
    font-variation-settings: 'wght' 450;
  }
  .font-medium {
    font-variation-settings: 'wght' 520;
  }
  .font-semibold {
    font-variation-settings: 'wght' 600;
  }
  .font-bold {
    font-variation-settings: 'wght' 700;
  }
  .font-black {
    font-variation-settings: 'wght' 750;
  }
}

@layer utilities {
  /* Default combobox styles */
  /* Default datepicker styles */
  .react-datepicker {
    @apply bg-white;
    @apply text-primary;
  }

  .dark .react-datepicker {
    @apply bg-gray-700;
  }

  .react-datepicker .react-datepicker__day--keyboard-selected,
  .react-datepicker .react-datepicker__day--selected {
    color: unset;
  }

  .dark .react-datepicker__day--keyboard-selected,
  .react-datepicker .react-datepicker__day--selected {
    @apply text-gray-400;
  }

  .react-datepicker__day:hover {
    @apply bg-gray-100;
  }

  .dark .react-datepicker__day:hover {
    @apply bg-gray-700;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected:hover {
    @apply bg-gray-100;
  }

  .dark .react-datepicker__day--keyboard-selected:hover,
  .dark .react-datepicker__day--selected:hover {
    @apply bg-gray-700;
  }

  .bg-pattern-birthday {
    background-image: url('/assets/backgroundBirthdayPatternDark.svg');
  }

  .dark .bg-pattern-birthday {
    background-image: url('/assets/backgroundBirthdayPatternLight.svg');
  }

  .bg-pattern-amie {
    background-image: url('/assets/backgroundPatternDark.svg');
  }

  .dark .bg-pattern-amie {
    background-image: url('/assets/backgroundPatternLight.svg');
  }
}

@layer components {
  /* Primary text in the app */
  .text-primary {
    @apply text-gray-900;
  }

  .dark .text-primary {
    @apply text-gray-100;
  }

  /* Lighter secondary text in the app */
  .text-secondary {
    @apply text-gray-500;
  }

  .dark .text-secondary {
    @apply text-gray-400;
  }

  /* Icon colors in the app */
  .text-icons {
    @apply text-gray-500;
  }

  .dark .text-icons {
    @apply text-gray-400;
  }

  /* Background color of the app */
  .bg-background {
    @apply bg-gray-50;
  }

  .dark .bg-background {
    @apply bg-gray-800;
  }

  /* Border color of the app */
  .bg-separator {
    @apply bg-black/[7%];
  }

  .dark .bg-separator {
    @apply bg-white/[7%];
  }

  /* Border color of the app */
  .border-separator {
    @apply border-black/[7%];
  }

  .dark .border-separator {
    @apply border-white/[7%];
  }

  /* Border color of background color alias */
  .border-background {
    @apply border-gray-50;
  }

  .dark .border-background {
    @apply border-gray-800;
  }

  /* Background colors for loader gradient */
  .bg-loader {
    stop-color: theme('colors.gray.200');
  }

  .dark .bg-loader {
    stop-color: theme('colors.gray.700');
  }

  .bg-loader-alt {
    stop-color: theme('colors.gray.300');
  }

  .dark .bg-loader-alt {
    stop-color: theme('colors.gray.600');
  }

  .shadow-homeCheckbox {
    box-shadow: inset 0 0 0 4px theme('colors.gray.200');
  }

  .dark .shadow-homeCheckbox {
    box-shadow: inset 0 0 0 4px theme('colors.gray.750');
  }

  .shadow-homeCheckbox-filled {
    box-shadow: inset 0 0 0 16px theme('colors.green.500');
  }

  .dark .shadow-homeCheckbox-filled {
    box-shadow: inset 0 0 0 16px theme('colors.green.500');
  }

  /* Background color of dropdowns */
  .bg-dropdown {
    @apply bg-white;
  }

  .dark .bg-dropdown {
    @apply bg-gray-900;
  }

  /* Background color of popovers */
  .bg-popover {
    @apply bg-white;
  }

  .dark .bg-popover {
    @apply bg-gray-700;
  }

  /* Striped background gradient */
  .bg-striped {
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.34),
      rgba(255, 255, 255, 0.34) 8px,
      rgba(255, 255, 255, 0) 8px,
      rgba(255, 255, 255, 0) 16px
    );
  }

  .dark .bg-striped {
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.06),
      rgba(255, 255, 255, 0.06) 8px,
      rgba(255, 255, 255, 0.09) 8px,
      rgba(255, 255, 255, 0.09) 16px
    );
  }

  /* Striped background gradient */
  .bg-striped-selected {
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2) 8px,
      rgba(255, 255, 255, 0) 8px,
      rgba(255, 255, 255, 0) 16px
    );
  }

  .dark .bg-striped-selected {
    background: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.06),
      rgba(255, 255, 255, 0.06) 8px,
      rgba(255, 255, 255, 0.09) 8px,
      rgba(255, 255, 255, 0.09) 16px
    );
  }

  /* Changelog markdown styles */

  .prose .frontmatter-markdown h3 {
    @apply text-lg;
  }

  .prose p,
  .prose li,
  .prose strong {
    @apply text-gray-500;
  }

  .dark .prose li,
  .dark .prose p,
  .dark .prose a {
    @apply text-gray-400;
  }

  .prose .frontmatter-markdown h1 {
    @apply text-xl;
  }

  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4 {
    @apply text-gray-800;
  }

  .dark .prose strong,
  .dark .prose h1,
  .dark .prose h2,
  .dark .prose h3,
  .dark .prose h4 {
    @apply text-gray-200;
  }

  .prose .frontmatter-markdown img,
  .prose .frontmatter-markdown video {
    @apply h-96 w-full rounded-2xl object-cover object-top;
  }

  .dark .prose video[data-mode='light'],
  .light .prose video[data-mode='dark'] {
    display: none;
  }

  .dark .prose img[data-mode='light'],
  .light .prose img[data-mode='dark'] {
    display: none;
  }
}
